import { ControllerFlowAPI, VisitorLogger } from '@wix/yoshi-flow-editor';
import {
  bookingsCalendarClick,
  bookingsCalendarTimePickerLoad,
  bookingsCalendarDatePickerLoad,
  bookingsCalendarErrorMessages,
  bookingsCalendarBookingDetailsLoad,
  bookingsContactInfoSaveSuccess,
  bookingsPaymentMethodSelectionNextClicked,
  bookingsLoginUserAccountAppointmentsRescheduleSuccess,
  bookingsCalendarPageLoaded,
  bookingsCalendarFiltersLoad,
} from '@wix/bi-logger-wixboost-ugc/v2';
import { CalendarState } from '../../components/BookingCalendar/controller';
import {
  WixOOISDKAdapter,
  BookingsQueryParams,
} from '@wix/bookings-adapter-ooi-wix-sdk';
import { BusinessInfo, Service } from '@wix/bookings-uou-types';
import { Preset } from '../../types/types';
import { isCalendarPage } from '../presets';
import { getAvailableServicesByPreset } from '../state/getAvailableServicesByPreset';

export interface CalendarBiLogger extends VisitorLogger {
  update: (state: CalendarState) => void;
  bookingsCalendarClick: typeof bookingsCalendarClick;
  bookingsCalendarPageLoaded: typeof bookingsCalendarPageLoaded;
  bookingsCalendarFiltersLoad: typeof bookingsCalendarFiltersLoad;
  bookingsCalendarTimePickerLoad: typeof bookingsCalendarTimePickerLoad;
  bookingsCalendarDatePickerLoad: typeof bookingsCalendarDatePickerLoad;
  bookingsCalendarErrorMessages: typeof bookingsCalendarErrorMessages;
  bookingsCalendarBookingDetailsLoad: typeof bookingsCalendarBookingDetailsLoad;
  bookingsContactInfoSaveSuccess: typeof bookingsContactInfoSaveSuccess;
  bookingsPaymentMethodSelectionNextClicked: typeof bookingsPaymentMethodSelectionNextClicked;
  bookingsLoginUserAccountAppointmentsRescheduleSuccess: typeof bookingsLoginUserAccountAppointmentsRescheduleSuccess;
}

export function createCalendarBiLogger(
  flowAPI: ControllerFlowAPI,
  initialState: CalendarState,
  wixSdkAdapter: WixOOISDKAdapter,
  settings: any,
  settingsParams: any,
  preset: Preset,
  businessInfo?: BusinessInfo,
): CalendarBiLogger {
  const { bi: viewerBiLogger } = flowAPI;

  const getServiceLocationIds = (service: Service) => {
    return service?.locations
      ?.map((location) => location?.businessLocation?.id)
      .filter((locationId) => locationId !== undefined);
  };

  const getServiceStaffIds = (service: Service) => {
    return service?.staffMembers?.map((staffMember) => staffMember.id);
  };

  const getServiceProperties = (state: CalendarState) => {
    const isServiceListDropdownEnabled = flowAPI.experiments.enabled(
      'specs.bookings.calendar.serviceListDropdown',
    );
    const services = getAvailableServicesByPreset({
      availableServices: state.availableServices,
      preset,
      isServiceListDropdownEnabled,
    });

    const serviceProperties = services.reduce(
      (previousServiceProperties, service) => {
        return {
          ...previousServiceProperties,
          [service.id]: {
            serviceId: service?.id,
            serviceType: service?.info.type,
            locationIds: getServiceLocationIds(service),
            staffMemberIds: getServiceStaffIds(service),
            paymentOptions: service?.payment?.offeredAs,
            connectedSolutions: [],
          },
        };
      },
      {},
    );
    return JSON.stringify(serviceProperties);
  };

  const getSelectedFilters = (state: CalendarState) => {
    const PRICE_OPTION = state.serviceVariants?.options;
    return JSON.stringify({ ...state.filterOptions, PRICE_OPTION });
  };

  const getPriceType = (state: CalendarState) =>
    JSON.stringify(
      state.serviceVariants?.options?.values?.map(({ type }) => type) || [],
    );

  const mapStateToDefaultBiParams = (state: CalendarState) => {
    const service = state?.availableServices[0];
    return {
      serviceType: service?.info.type,
      serviceId: service?.id,
      selectedTimezone: state?.selectedTimezone,
      errorMessage: JSON.stringify(state?.calendarErrors),
      serviceProperties: getServiceProperties(state),
      selectedFilters: getSelectedFilters(state),
      selectedDate: state?.selectedDate,
      selectedRange: JSON.stringify(state?.selectedRange),
      bookingId: state?.rescheduleBookingDetails?.id,
      priceType: getPriceType(state),
    };
  };

  const updateDefaultBiParams = (state: CalendarState) => {
    viewerBiLogger?.updateDefaults(mapStateToDefaultBiParams(state));
  };

  viewerBiLogger?.updateDefaults({
    pageName: preset,
    referralInfo: wixSdkAdapter.getUrlQueryParamValue(
      BookingsQueryParams.REFERRAL,
    ),
    businessProperties: JSON.stringify({
      language: businessInfo?.language,
      countryCode: businessInfo?.countryCode,
    }),
    ...mapStateToDefaultBiParams(initialState),
    defaultDateAtFirstLoad: settings.get(settingsParams.initializeCalendarDate),
    layout: settings.get(settingsParams.calendarLayout),
  });

  return Object.assign(viewerBiLogger!, {
    update: updateDefaultBiParams,
  }) as CalendarBiLogger;
}
